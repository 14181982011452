import React from 'react';
import classnames from 'classnames';

import classes from './WorkTypeTag.module.scss';

const WorkTypeTag = ({ tag, className }) => {
  const genre = tag?.name;
  const type = tag?.slug;
  if (genre) {
    return (
      <div
        className={classnames(classes.workTypeTag, className, {
          [classes[`workTypeTag_type_${type}`]]: type,
          [classes.isBlur]: tag?.id === -1,
        })}
      >
        {genre}
      </div>
    );
  }

  return <div />;
};

export default WorkTypeTag;
