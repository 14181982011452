import React, { useCallback } from 'react';

import AutoCompleteDropdown from 'components/uiLibrary/FormInputs/BaseComponents/AutoCompleteDropdown';

import globalQueries from 'containers/Globals/queries';
import prodQueries from 'containers/Productions/queries';

import { useAppliedCountryFilter } from 'utils/hooks/useLocationFilter';

import { TP, AGGREGATION_TYPES } from 'constants/index';

import { useTranslation } from 'src/i18n';

const CountryDropdown = ({
  className,
  value,
  onChange,
  aggregationOn,
  aggregationFilters,
  isDisabled = false,
  onBlur,
  disablePortal = false,
  label = `${TP}.m_COUNTRY`,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const aggregationQuery = useCallback(
    props =>
      prodQueries.getAggregatedEntities({
        ...props,
        aggregationOn,
        aggregationType: AGGREGATION_TYPES.COUNTRY,
        filters: aggregationFilters,
      }),
    [aggregationFilters, aggregationOn],
  );

  const { data: country } = useAppliedCountryFilter(value);

  return (
    <AutoCompleteDropdown
      className={className}
      label={t(label)}
      value={country}
      onChange={onChange}
      onBlur={onBlur}
      searchQuery={globalQueries.getCountries}
      {...(aggregationOn && { aggregationQuery })}
      isDisabled={isDisabled}
      disablePortal={disablePortal}
    />
  );
};

export default CountryDropdown;
