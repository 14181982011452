import { TP, ENTITY_MAIN_TABS, ENTITY_TYPE, PRIVACY_ATTRIBUTE_IDS, ENTITY_DETAILS_TAB } from 'constants/index';

import getLinkProps from 'utils/globals/getLinkProps';
import { getPageTitle } from 'utils/globals';

import { getProfileProfession } from '../../artists';

const getTitle = ({ entity }) => {
  const artistName = getPageTitle({ entity, entityType: ENTITY_TYPE.ARTIST });
  const professionalSummary = getProfileProfession(entity) || entity?.summary;

  return [artistName, professionalSummary].filter(Boolean).join(', ');
};

const getVideosCount = ({ showreels, media, subPath }) => {
  const subPathCount = {
    [ENTITY_DETAILS_TAB.INTRO_SHOWREEL]: showreels?.intro?.total,
    [ENTITY_DETAILS_TAB.AUDITION_SHOWREEL]: showreels?.audition?.total,
    [ENTITY_DETAILS_TAB.GENERAL]: media?.videos?.featured?.total,
  };

  return subPathCount[subPath] || media?.videos?.total;
};

const getSEOTitle = ({ entity, activeTab, t, subPath, seasonId, performanceCount }) => {
  const title = getTitle({ entity });
  const { about, productions, showreels, media, repertoire } = entity?.stats;
  const sections = [
    ...(about?.exists ? [t(`${TP}.m_ARTIST_BIOGRAPHY`)] : []),
    ...(productions?.exists ? [t(`${TP}.FN_PERFORMANCES`)] : []),
    ...(media?.videos?.total ? [t(`${TP}.OPERA_ONLINE`)] : []),
    ...(repertoire?.future?.exists || repertoire?.past?.exists ? [t(`${TP}.m_ARTIST_REPERTOIRE`)] : []),
  ];
  const ticketText = productions?.future?.exists ? `${t(`${TP}.AND`)} ${t(`${TP}.FN_TICKETS`)}` : '';
  const imagesCount = subPath === ENTITY_DETAILS_TAB.GENERAL ? media?.images?.featured?.total : media?.images?.total;
  const videosCount = getVideosCount({ showreels, media, subPath });

  const metaTitle = () => {
    switch (activeTab?.key) {
      case ENTITY_MAIN_TABS.PERFORMANCES:
        if (seasonId) {
          return t('PERFORMANCE_YEAR_META_TITLE', { performanceCount, year: seasonId });
        }
        return subPath === ENTITY_DETAILS_TAB.PAST
          ? `${productions?.past?.total} ${t('PAST_PRODUCTIONS')}`
          : `${t(`${TP}.FN_PERFORMANCES`)} ${ticketText}`;
      case ENTITY_MAIN_TABS.REPERTOIRE:
        return t(`${TP}.m_ARTIST_REPERTOIRE`);
      case ENTITY_MAIN_TABS.BIO:
        return t(`${TP}.m_BIO`);
      case ENTITY_MAIN_TABS.VIDEOS:
        return `${t(`${TP}.m_WATCH`)} ${videosCount} ${t(`${TP}.m_VIDEOS`)}`;
      case ENTITY_MAIN_TABS.IMAGES:
        return `${imagesCount} ${t(`${TP}.m_IMAGES`)}`;
      case ENTITY_MAIN_TABS.REVIEWS:
        return `${t(`${TP}.FN_VIEW`)} ${t(`${TP}.m_REVIEWS`)}`;
      case ENTITY_MAIN_TABS.CONTACT:
        return `${t(`${TP}.m_CONTACT`)}, ${t(`${TP}.m_REPRESENTATIONDETAIL`)}`;
      default:
        if (!sections.length) return '';
        return sections.length === 1
          ? sections[0]
          : `${sections.slice(0, sections.length - 1).join(', ')} ${t(`${TP}.AND`)} ${sections[sections.length - 1]}`;
    }
  };

  const metaTitleText = metaTitle();
  const separator = metaTitleText.length ? ' - ' : '';
  return `${title}${separator}${metaTitleText} ${t(`${TP}.FN_ON`)} ${t(`${TP}.m_OPERABASE`)}`;
};

const getSEODescription = ({ t, entity, activeTab, subPath, productions: productionList, seasonId }) => {
  const { performances, productions, repertoire, media } = entity?.stats;
  const artistName = getPageTitle({ entity, entityType: ENTITY_TYPE.ARTIST });
  const agencies = entity?.agencies
    ?.filter(agency => agency?.privacyAttributes?.[0]?.id === PRIVACY_ATTRIBUTE_IDS.PUBLIC && agency?.name)
    ?.map(agency => agency?.name);

  const performanceMetaDesc = [
    performances?.future?.exists && t('UPCOMING'),
    performances?.future?.exists && productions?.past?.exists && t(`${TP}.AND`),
    productions?.past?.exists && t('PAST'),
    productions?.exists && t('PERFORMANCES'),
  ]
    .filter(desc => desc)
    .join(' ');
  const productionNames = productionList?.data
    ?.slice(0, 2)
    ?.map(production => production?.name || production?.productionWorks?.[0]?.work?.name)
    ?.join(', ');

  const repertoireMetaDesc = repertoire?.exists ? t('REPERTOIRE') : '';
  const bookTicketsMetaDesc = performances?.future?.exists ? t('BOOK_TICKETS') : '';
  const mediaMetaDesc = [media?.images?.exists && t(`${TP}.m_IMAGES`), media?.videos?.exists && t('VIDEO_GALLERY')]
    .filter(desc => desc)
    .join(` ${t(`${TP}.AND`)} `);
  const contactMetaText = [entity?.contacts?.length && t('ARTIST'), agencies?.length && t('AGENCIES')]
    .filter(text => text)
    .join('/');
  const contactMetaDesc =
    entity?.contacts?.length || entity?.agencies?.length ? `${t('CONTACT')} ${contactMetaText}` : '';
  const tags = [performanceMetaDesc, repertoireMetaDesc, mediaMetaDesc, bookTicketsMetaDesc, contactMetaDesc]
    .filter(desc => desc)
    .join(', ');

  const metaDescription = () => {
    switch (activeTab?.key) {
      case ENTITY_MAIN_TABS.PERFORMANCES:
        if (seasonId) {
          return `${t('PERFORMANCE_YEAR_META_DESC', { entityName: artistName, year: seasonId })} ${t(
            `${TP}.FN_ON`,
          )} ${t(`${TP}.m_OPERABASE`)}`;
        }
        return t('ARTIST_META_DESC', {
          entityName: artistName,
          tags:
            subPath === ENTITY_DETAILS_TAB.PAST
              ? `${t('TO_VIEW')} ${t('PAST_PERFORMANCE_LIKE', { productionNames })}`
              : `${t('TO_VIEW')} ${performanceMetaDesc}`,
        });
      case ENTITY_MAIN_TABS.REPERTOIRE:
        return t('REPERTOIRE_META_DESC', { entityName: artistName });
      case ENTITY_MAIN_TABS.BIO:
        return t('BIOGRAPHY_META_DESC', { entityName: artistName });
      case ENTITY_MAIN_TABS.VIDEOS:
        return t('VIDEOS_META_DESC', { entityName: artistName });
      case ENTITY_MAIN_TABS.IMAGES:
        return t('PHOTOS_META_DESC', { entityName: artistName });
      case ENTITY_MAIN_TABS.REVIEWS:
        return t('REVIEWS_META_DESC', { entityName: artistName });
      case ENTITY_MAIN_TABS.CONTACT:
        return t('CONTACT_META_DESC', {
          entityName: artistName,
          agencyNames: agencies?.length ? `/${agencies?.join(', ')}` : '',
        });
      default:
        return t('ARTIST_META_DESC', {
          entityName: artistName,
          tags: tags ? `${t('TO_VIEW')} ${tags}` : '',
        });
    }
  };

  return metaDescription();
};

const getOgImages = ({ entity }) => {
  const ogImages = [];
  const image = entity?.image?.medium;

  if (image) {
    ogImages.push({
      url: image,
      alt: getPageTitle({ entity, entityType: ENTITY_TYPE.ARTIST }),
    });
  }

  return ogImages;
};

const getSocialProfileSEO = ({ entity, entityType }) => {
  const name = entity?.name;
  const image = entity?.metaInfo?.img;
  const { url } = getLinkProps({ entity, entityType });

  return {
    type: 'ProfilePage',
    dateCreated: entity?.createdAt,
    dateModified: entity?.updatedAt,
    mainEntity: {
      '@type': 'Person',
      name,
      image,
      sameAs: [url],
    },
  };
};

export default {
  getSEODescription,
  getOgImages,
  getSocialProfileSEO,
  getTitle,
  getSEOTitle,
};
