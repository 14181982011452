import { useMemo } from 'react';
import { VIDEO_ATTRIBUTE_SLUGS, TP } from 'constants/consts';
import { createDate } from 'utils/date';
import { useTranslation } from 'src/i18n';

const getProductionDateAttributes = video => video?.tags?.filter(item => item.tagType?.id === 6)?.[0]?.productiondate;

const getBasicDetails = (video, type, t) => {
  const production = video?.production;
  const company = production?.companies?.[0];
  const work = production?.works?.[0];
  const name = work?.original_name || work?.name || '';
  const canonical = work?.name && work?.name !== name && work?.name;
  const composer = work?.creators?.[0]?.profile?.name;
  const productionDate = getProductionDateAttributes(video);
  const attributes = productionDate?.attributes?.[0];
  const venue = productionDate?.venue;
  const startDate = productionDate?.startDate
    ? createDate(productionDate?.startDate).format('YYYY-MM-DD')
    : createDate(new Date()).format('YYYY-MM-DD');
  const endDate = productionDate?.endDate
    ? createDate(productionDate?.endDate).format('YYYY-MM-DD')
    : createDate(new Date()).format('YYYY-MM-DD');
  const startTime = productionDate?.startTime;
  const endTime = productionDate?.endTime;

  const title = [];
  title.push(`${name} ${canonical ? `(${canonical})` : ''}, ${composer}`);
  if (company) {
    title.push(`${t(`${TP}.SEO_PERFORMED_AT`)} ${company?.name}`);
  }
  if (venue) {
    title.push(`${t(`${TP}.SEO_PERFORMED_AT`)} ${venue?.name}`);
  }
  const secondaryTitle = title.join(' | ');
  const description = [];
  description.push(`${name} ${canonical ? `(${canonical})` : ''}, ${composer}`);
  if (type === VIDEO_ATTRIBUTE_SLUGS.LIVESTREAM) {
    description.push(`${t(`${TP}.SEO_LIVESTREAM_ON`)} ${startDate}`);
  } else if (type === VIDEO_ATTRIBUTE_SLUGS.VIDEO_ON_DEMAND) {
    description.push(`${t(`${TP}.SEO_VIDEO_AVAILABLE_ON`)} ${startDate}`);
    if (venue) {
      description.push(`${t(`${TP}.VENUE_NAME`)} ${venue?.name}`);
    }
    if (productionDate?.startDate < new Date()) {
      if (attributes?.source) {
        description.push(
          `${t(`${TP}.SEO_WHERE_CAN_YOU_WATCH`)}: ${attributes?.source},${attributes?.deviceAvailability?.join(',')}`,
        );
      }
    }
  }
  const secondaryDescription = description.join(' | ');

  return {
    production,
    company,
    work,
    composer,
    attributes,
    venue,
    startDate,
    endDate,
    secondaryTitle,
    secondaryDescription,
    ...(startTime && { startTime }),
    ...(endTime && { endTime }),
  };
};
const getFallbackTitleDescription = (video, type, t) => {
  const { secondaryTitle, secondaryDescription } = getBasicDetails(video, type, t);

  return {
    secondaryTitle,
    secondaryDescription,
  };
};
const getVodVideoSEO = ({ t, entity: video }) => {
  const { title, description, createdAt } = video;
  const { secondaryTitle, secondaryDescription } = getFallbackTitleDescription(
    video,
    VIDEO_ATTRIBUTE_SLUGS.VIDEO_ON_DEMAND,
    t,
  );
  const thumbnail = video?.poster?.medium;
  const thumbnailUrls = thumbnail ? [thumbnail] : null;
  const attributes = getProductionDateAttributes(video)?.attributes;
  const url = attributes?.[0]?.url;

  return {
    name: title || secondaryTitle,
    description: description || secondaryDescription,
    contentUrl: url || '',
    thumbnailUrls,
    uploadDate: createdAt,
    keyOverride: `VodStructuredData-${video?.id}`,
  };
};

const getLsVideoSEO = ({ t, entity: video }) => {
  const { title, description, createdAt } = video;
  const { secondaryTitle, secondaryDescription, startDate, endDate, startTime, endTime } = getBasicDetails(
    video,
    VIDEO_ATTRIBUTE_SLUGS.LIVESTREAM,
    t,
  );
  const thumbnail = video?.poster?.medium;
  const thumbnailUrls = thumbnail ? [thumbnail] : null;
  const productionCompanies = video?.production?.companies;
  const productionWorks = video?.production?.works;
  const attributes = getProductionDateAttributes(video)?.attributes;
  const url = attributes?.[0]?.url;
  const castList = video?.tags?.filter(item => item.tagType?.id === 9);

  // Optional properties for LS, we need to modify the Next seo lib
  let offers;
  let workPerformed;
  if (attributes?.cost || attributes?.currency) {
    offers = [
      {
        price: attributes?.cost,
        priceCurrency: attributes?.currency,
        url,
      },
    ];
  }
  if (productionWorks?.length > 0)
    workPerformed = {
      '@type': 'CreativeWork',
      name: productionWorks?.[0]?.name,
    };
  const performerList =
    castList?.length > 0 &&
    castList
      ?.slice(0, 5)
      .map(item => item?.cast?.profile)
      .filter(Boolean);

  const organizers = productionCompanies?.map(item => ({
    '@type': 'Organization',
    name: item?.name,
  }));
  const composers = productionWorks?.map(item => ({
    '@type': 'Person',
    name: item?.composer?.name,
  }));

  const performers =
    performerList &&
    performerList?.map(item => ({
      name: item?.name,
      performer: {
        '@type': 'Person',
        name: item?.name,
      },
      '@type': 'PerformanceRole',
      roleName: item?.role,
    }));

  const publication = {
    name: title || secondaryTitle,
    isLiveBroadcast: 'true',
    startDate: startTime
      ? createDate(`${startDate}T${startTime}:00`).toISOString()
      : createDate(startDate).toISOString(),
    endDate: endTime
      ? createDate(`${endDate}T${endTime}:00Z`).toISOString()
      : createDate(`${endDate}T23:59:00Z`).toISOString(),
  };

  return {
    name: title || secondaryTitle,
    description: description || secondaryDescription,
    contentUrl: url || '',
    thumbnailUrls,
    uploadDate: createdAt,
    publication,
    keyOverride: `LsStructuredData-${video?.id}`,
  };
};

const getVideoSEO = ({ entity: video }) => {
  const fallbackSEO = {
    name: video?.title || video?.description || 'video',
    description: video?.description || video?.title || 'description',
  };

  const { title, description, url, createdAt } = video || {};
  const thumbnail = video?.thumbnail?.urls?.medium;
  const thumbnailUrls = thumbnail ? [thumbnail] : null;

  return {
    name: title || fallbackSEO?.name || '',
    description: description || fallbackSEO?.description || '',
    contentUrl: url,
    embedUrl: url,
    thumbnailUrls,
    uploadDate: createdAt || '',
    keyOverride: `VideoStructuredData-${video?.id}`,
  };
};

export default {
  getVideoSEO,
  getLsVideoSEO,
  getVodVideoSEO,
};
