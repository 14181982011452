import React, { useMemo, useCallback, useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';

import { ENTITY_MAIN_TABS, URL_SLUG_TYPES } from 'constants/index';
import { FILTER_SLUG_TYPE } from 'constants/filters';

import { getContextualFilters } from 'utils/globals/filters';
import getLinkProps from 'utils/globals/getLinkProps';
import { getStubPageRouteContext } from 'utils/globals';

const RouteContext = React.createContext({});

const RouteContextProvider = ({ children, value }) => {
  const router = useRouter();
  const { query } = router;
  const { entity, entityType, activeTab, tabs } = value || {};

  const [context, setContext] = useState(getStubPageRouteContext({ ...query, entityType }));

  const [draftFilters, setDraftFilters] = useState(context?.filters);
  const [draftResults, setDraftResults] = useState({});
  const [showFilterPopup, setShowFilterPopup] = useState(false);

  const activeTabKey = useMemo(() => activeTab?.key, [activeTab]);
  const validFilters = useMemo(() => getContextualFilters({ entityType, tabKey: activeTabKey }), [
    entityType,
    activeTabKey,
  ]);
  const validFitlersKey = useMemo(() => validFilters?.map(type => FILTER_SLUG_TYPE[type]), [validFilters]);

  const filterTab = useMemo(
    () =>
      activeTab?.key === ENTITY_MAIN_TABS.HOME
        ? tabs?.find(tab => ENTITY_MAIN_TABS.PERFORMANCES === tab?.key)
        : activeTab,
    [activeTab, tabs],
  );

  const getAppliedFilters = useCallback(
    (filters, forDifferentTab = false) =>
      activeTabKey === ENTITY_MAIN_TABS.HOME && !forDifferentTab
        ? pick(filters || context?.filters, [
            URL_SLUG_TYPES.PRODUCTION_PERIOD,
            URL_SLUG_TYPES.VIEW_MODE,
            URL_SLUG_TYPES.DATE,
          ])
        : pick(filters || context?.filters, validFitlersKey),
    [validFitlersKey, context, activeTabKey],
  );

  const appliedFilters = getAppliedFilters();

  const checkIfFiltersChanged = useCallback(
    (newFilters = {}) => {
      if (isEqual(Object.keys(appliedFilters), Object.keys(newFilters))) {
        return (
          Object.values(
            mapValues(newFilters, (values, key) => {
              if ([URL_SLUG_TYPES.DATE, URL_SLUG_TYPES.PRODUCTION_PERIOD, URL_SLUG_TYPES.VIEW_MODE].includes(key)) {
                return !isEqual(values, appliedFilters?.[key]);
              }

              return !isEqual(
                values?.map(filter => filter?.id),
                appliedFilters?.[key]?.map(filter => filter?.id),
              );
            }),
          )?.findIndex(isChanged => isChanged) > -1
        );
      }

      return true;
    },
    [appliedFilters],
  );

  const mergeContextDetails = useCallback(({ oldContext, newContext }) => {
    const oldFilters = oldContext?.filters;
    const newFilters = newContext?.filters;

    const mergedFilters = mapValues(newFilters, (values, key) => {
      if ([URL_SLUG_TYPES.DATE, URL_SLUG_TYPES.PRODUCTION_PERIOD, URL_SLUG_TYPES.VIEW_MODE].includes(key)) {
        return values;
      }

      const newValues = values?.map(newFilter => {
        const label = newFilter?.label || oldFilters?.[key]?.find(oldFilter => oldFilter?.id === newFilter?.id)?.label;

        return { ...newFilter, label };
      });

      return newValues;
    });

    const filters = {
      ...(oldContext?.mainPath !== newContext?.mainPath && oldFilters),
      ...mergedFilters,
    };

    return {
      ...newContext,
      filters,
    };
  }, []);

  const getLink = useCallback(
    filters =>
      (() => {
        const shouldRetainTab =
          (Object.keys(filters).length === 1 &&
            (filters?.[URL_SLUG_TYPES.PRODUCTION_PERIOD] || filters?.[URL_SLUG_TYPES.VIEW_MODE])) ||
          Object.keys(filters).length === 0;
        let suffix = shouldRetainTab ? activeTabKey : filterTab?.key;
        if (suffix === ENTITY_MAIN_TABS.HOME) {
          suffix = '';
        }

        return getLinkProps({
          entityType,
          entity,
          suffix,
          filters,
        });
      })(),
    [entityType, entity, filterTab, activeTabKey],
  );

  const updateFiltersToURL = useCallback(
    (filters, shouldReplace = false) => {
      const linkProps = getLink(filters);

      if (shouldReplace) {
        router.replace(linkProps.href, linkProps.url, { shallow: true });
      } else {
        router.push(linkProps.href, linkProps.url, { shallow: true });
      }
    },
    [getLinkProps, router],
  );

  const setFilters = useCallback(
    newFilters => {
      if (checkIfFiltersChanged(newFilters)) {
        const newAppliedFilters = getAppliedFilters(newFilters, true);

        updateFiltersToURL(newAppliedFilters);
      }
    },
    [getAppliedFilters, updateFiltersToURL, checkIfFiltersChanged],
  );

  useEffect(() => {
    if (!isEmpty(appliedFilters) && activeTabKey !== ENTITY_MAIN_TABS.HOME) {
      updateFiltersToURL(getAppliedFilters(), true);
    }

    setDraftResults([]);
  }, [activeTabKey]);

  useEffect(() => {
    const newContext = getStubPageRouteContext({ ...query, entityType });

    if (checkIfFiltersChanged(newContext?.filters)) {
      setContext(oldContext => mergeContextDetails({ oldContext, newContext }));
    }
  }, [query]);

  const updateDraftFetch = ({ key, count }) => {
    setDraftResults({
      ...draftResults,
      [key]: count,
    });
  };

  const draftCount = useMemo(() => {
    const results = Object.values(draftResults);

    if (results.length === 0) {
      return null;
    }

    return results.reduce((acc, count) => acc + count, 0);
  }, [draftResults]);

  const setFilterPopupVisibility = useCallback(
    visibility => {
      if (visibility) {
        setDraftFilters(appliedFilters);
      }

      setShowFilterPopup(visibility);
    },
    [appliedFilters],
  );

  const hasAppliedFilters = useMemo(() => Object.keys(appliedFilters)?.length > 0, [appliedFilters]);

  return (
    <RouteContext.Provider
      value={{
        activeTab,
        context,
        filters: appliedFilters,
        setFilters,
        draftFilters,
        setDraftFilters,
        draftCount,
        updateDraftFetch,
        validFilters,
        router,
        showFilterPopup,
        setFilterPopupVisibility,
        hasAppliedFilters,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

export const useRouteContext = () => useContext(RouteContext);

export default RouteContextProvider;
