/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback, useState } from 'react';
import _isEmpty from 'lodash-es/isEmpty';

import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import useTracking from 'components/Globals/Analytics';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester/TrackingTester';

import { useTranslation } from 'src/i18n';

import classes from './Switch.module.scss';

export const SWITCH_TYPES = {
  ON_OR_OFF: 'ON_OR_OFF',
  THIS_OR_THAT: 'THIS_OR_THAT',
};

const Switch = ({
  ns = 'NS_APP_GLOBALS',
  type = SWITCH_TYPES.ON_OR_OFF,
  leftOption,
  rightOption,
  onChange,
  value,
  defaultValue,
  controlled = false,
  disabled = false,
  trackingData,
  skipTracking = false,
}) => {
  const { t } = useTranslation(ns);
  const track = useTracking();
  const trackingClasses = useTrackingClickStyles(trackingData, skipTracking);

  const [isLocalValue, setIsLocalValue] = useState(() => {
    if (defaultValue) {
      return defaultValue;
    }

    if (type === SWITCH_TYPES.THIS_OR_THAT) {
      return leftOption?.value;
    }

    return null;
  });

  const onChangeHandler = useCallback(
    newValue => {
      if (!controlled) {
        setIsLocalValue(newValue?.value);
      }

      if (onChange) {
        onChange(newValue);
      }

      if (!_isEmpty(trackingData)) {
        track.click({ ...trackingData, meta: { value: newValue?.value } });
      }
    },
    [controlled, onChange],
  );

  const isLeftActive = controlled ? value === leftOption?.value : isLocalValue === leftOption?.value;
  const isRightActive = controlled ? value === rightOption?.value : isLocalValue === rightOption?.value;

  const { leftLabelColor, rightLabelColor } = useMemo(() => {
    if (type === SWITCH_TYPES.ON_OR_OFF) {
      return {
        leftLabelColor: isLeftActive ? 'primary' : 'tertiary',
        rightLabelColor: isRightActive ? 'link' : 'tertiary',
      };
    }

    return {
      leftLabelColor: isLeftActive ? 'link' : 'primary',
      rightLabelColor: isRightActive ? 'link' : 'primary',
    };
  }, [isLeftActive, isRightActive, type]);

  return (
    <div className={classnames(classes.switch, trackingClasses, { [classes.disabled]: disabled })}>
      <Typography size="12" color={leftLabelColor} weight="medium" onClick={() => onChangeHandler(leftOption)}>
        {t(leftOption?.label)}
      </Typography>
      <div
        className={classnames(classes.slider, {
          [classes.slider_active]: isRightActive || (type === SWITCH_TYPES.THIS_OR_THAT && isLeftActive),
          [classes.slider_active_left]: type === SWITCH_TYPES.THIS_OR_THAT && isLeftActive,
          [classes.slider_active_right]: isRightActive,
        })}
        onClick={() => onChangeHandler(isLeftActive ? rightOption : leftOption)}
      />
      <Typography size="12" color={rightLabelColor} weight="medium" onClick={() => onChangeHandler(rightOption)}>
        {t(rightOption?.label)}
      </Typography>
    </div>
  );
};

export default Switch;
