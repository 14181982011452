import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import styles from './PageWrapper.module.scss';

const ScrollToTop = ({ hideScrollToTop = false, container, iconSize = 24, className, scrollStartsAt = 1000 }) => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    function onScroll() {
      const currentPosition = container ? container.scrollTop : window.pageYOffset;
      if (currentPosition > scrollStartsAt) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    }

    (container ?? window).addEventListener('scroll', onScroll);
    return () => (container ?? window).removeEventListener('scroll', onScroll);
  }, [container]);

  const scrollTop = () => (container ?? window).scrollTo({ top: 0, behavior: 'smooth' });

  const accessibleOnClick = handler => ({
    role: 'button',
    onClick: handler,
  });

  return (
    <>
      {!hideScrollToTop && showScrollButton && (
        <div {...accessibleOnClick(scrollTop)} className={classnames(styles.scrollArrowContainer, className)}>
          <SpriteIcon icon="arrow_upward" className={styles.scrollArrowIcon} size={iconSize} />
        </div>
      )}
    </>
  );
};

export default ScrollToTop;
