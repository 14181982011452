import orderBy from 'lodash/orderBy';
import { TP } from 'constants/index';
import { getCountryCity } from 'utils/common';
import { getSeoProductionName } from 'utils/productionDataHelper';
import { createSeoStringFromArray } from 'utils/seo';

export const getMusicalWorkDate = productions => {
  const production = productions?.data?.[0];
  const isSame = production?.minDate === production?.maxDate;
  const endValue = isSame ? null : production?.maxDate;
  return [production?.minDate, endValue].filter(Boolean).join(' - ');
};

export const getWorkInformation = work => {
  const name = work?.original_name ?? work?.name ?? '';
  const composer = work?.composers?.map(c => c?.name)?.join(', ') ?? '';
  const workType = work?.workType?.name ?? '';

  return { name, composer, workType };
};

const getSEODescription = ({ t, entity }) => {
  const { composer, workType } = getWorkInformation(entity);
  const name = getSeoProductionName({ work: entity });
  const seoDescription = [name, composer, workType];

  seoDescription.push(t(`${TP}.FN_VIEW_MORE`));

  return createSeoStringFromArray(seoDescription);
};

const getSEOTitle = ({ t, entity, productions }) => {
  let titleParams = [];
  const { name, composer, workType } = getWorkInformation(entity);
  titleParams = [t(`${TP}.WORK_BY_COMPOSER`, { work: name, composer }), workType, t(`${TP}.m_OPERABASE`)];
  const date = getMusicalWorkDate(productions);

  if (date?.trim()?.length > 0) {
    titleParams.push(date);
  }

  const filteredParams = titleParams.filter(Boolean);
  return createSeoStringFromArray(filteredParams);
};

export default {
  getSEODescription,
  getSEOTitle,
};
