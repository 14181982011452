import React, { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';
import EntityProfileSelector from 'components/Globals/EntityProfileSelector';
import { SECTIONS, COMPONENTS, SUB_COMPONENTS, CATEGORIES } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { useAppliedCountryFilter, useAppliedCityFilter } from 'utils/hooks/useLocationFilter';

import { TP, ENTITY_TYPE } from 'constants/index';
import { FILTER_TYPES, FILTER_SLUG_TYPE } from 'constants/filters';

import { useTranslation } from 'src/i18n';

import OrgListingFilterDropdown from '../OrgListingFilterDropdown';
import classes from './IndexPageFilters.module.scss';

const trackingData = {
  section: SECTIONS.ORGANIZATION_LISTING,
  component: COMPONENTS.FILTERS,
  category: CATEGORIES.SEARCH,
};

export const useOrgIndexPageFiltersMeta = () => {
  const { orgTypeSlug, organizationType, filters, hasAppliedFilters } = usePageContext();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const noIndexPage = !!filters?.query;
  const showTabularList = filters?.page > 0 || !!filters?.letter || !!filters?.query;

  const appliedCountryFilter = filters?.[FILTER_SLUG_TYPE[FILTER_TYPES.COUNTRY]]?.[0];
  const { data: selectedCountry } = useAppliedCountryFilter(appliedCountryFilter);

  const appliedCityFilter = filters?.[FILTER_SLUG_TYPE[FILTER_TYPES.CITY]]?.[0];
  const { data: selectedCity } = useAppliedCityFilter(appliedCityFilter);

  const hasAppliedCountryFilter = !!selectedCountry || false;
  const hasAppliedCityFilter = !!selectedCity || false;
  const hasAppliedOrgTypeFilter = !!organizationType || false;

  const title = useMemo(() => {
    if (hasAppliedCountryFilter || hasAppliedCityFilter) {
      let location = hasAppliedCountryFilter ? selectedCountry?.name : '';

      if (hasAppliedCityFilter) {
        if (location) {
          location += ', ';
        }

        location += selectedCity?.name;
      }

      return t(`${TP}.FN_ORG_TYPE_IN_LOCATION`, {
        orgType: organizationType?.name || t(`${TP}.FN_ORGANIZATIONS`),
        location,
      });
    }

    if (hasAppliedOrgTypeFilter) {
      return organizationType?.name;
    }

    return t(`${TP}.FN_ORGANIZATIONS`);
  }, [
    t,
    organizationType,
    selectedCountry,
    selectedCity,
    hasAppliedCountryFilter,
    hasAppliedCityFilter,
    hasAppliedOrgTypeFilter,
  ]);

  const shouldFetchTrendingList = useMemo(() => {
    if (!hasAppliedFilters) {
      return false;
    }

    const isValidOrgTypeSelection =
      !orgTypeSlug || (orgTypeSlug === organizationType?.slug && organizationType?.pro >= 8);

    if (hasAppliedCityFilter) {
      return isValidOrgTypeSelection && selectedCity?.pro > 0;
    }

    if (hasAppliedCountryFilter) {
      return isValidOrgTypeSelection && selectedCountry?.pro > 0;
    }

    return isValidOrgTypeSelection;
  }, [
    hasAppliedFilters,
    hasAppliedCityFilter,
    hasAppliedCountryFilter,
    orgTypeSlug,
    organizationType?.pro,
    organizationType?.slug,
    selectedCity?.pro,
    selectedCountry?.pro,
  ]);

  const trendingCount = useMemo(() => {
    if (shouldFetchTrendingList) {
      if (hasAppliedCityFilter) {
        return selectedCity?.total;
      }

      if (hasAppliedCountryFilter) {
        return selectedCountry?.total;
      }

      return organizationType?.total;
    }

    return null;
  }, [
    hasAppliedCityFilter,
    hasAppliedCountryFilter,
    organizationType?.total,
    selectedCity?.total,
    selectedCountry?.total,
    shouldFetchTrendingList,
  ]);

  return {
    noIndexPage,
    title,
    selectedOrgType: organizationType,
    selectedCountry,
    selectedCity,
    hasAppliedOrgTypeFilter,
    hasAppliedCountryFilter,
    hasAppliedCityFilter,
    hasAppliedFilters,
    showTabularList,
    shouldFetchTrendingList,
    trendingCount,
  };
};

const IndexPageFilters = () => {
  const { navigate, organizationType, filters, applyFilters } = usePageContext();
  const { selectedCountry, selectedCity } = useOrgIndexPageFiltersMeta();

  const onChangeFilter = useCallback(
    ({ filterUpdates, clearFilterKeys = [] }) => {
      const pageFilterKey = FILTER_SLUG_TYPE[FILTER_TYPES.PAGE];
      const pageFilterCurrentValue = filters?.[pageFilterKey];
      const finalFiltersExcludingPage = {
        ...omit(filters, [...clearFilterKeys, pageFilterKey]),
        ...filterUpdates,
      };

      applyFilters({
        entityType: ENTITY_TYPE.ORGANIZATION,
        filters: {
          ...finalFiltersExcludingPage,
          ...(pageFilterCurrentValue && Object.keys(finalFiltersExcludingPage).length > 0
            ? { [pageFilterKey]: 1 }
            : {}),
        },
      });
    },
    [filters, applyFilters],
  );

  const onSelectOrganization = useCallback(
    ({ entity }) => {
      if (entity) {
        navigate.to(navigate.getLinkProps({ entityType: ENTITY_TYPE.ORGANIZATION, entity }));
      } else {
        const queryFilterKey = FILTER_SLUG_TYPE[FILTER_TYPES.QUERY];
        const pageFilterKey = FILTER_SLUG_TYPE[FILTER_TYPES.PAGE];
        const pageFilterCurrentValue = filters?.[pageFilterKey];
        const finalFiltersExcludingPageAndQuery = omit(filters, [pageFilterKey, queryFilterKey]);

        applyFilters({
          entityType: ENTITY_TYPE.ORGANIZATION,
          filters: {
            ...finalFiltersExcludingPageAndQuery,
            ...(pageFilterCurrentValue && Object.keys(finalFiltersExcludingPageAndQuery).length > 0
              ? { [pageFilterKey]: 1 }
              : {}),
          },
        });
      }
    },
    [applyFilters, filters, navigate],
  );

  const onSearchOrganization = useCallback(
    query => {
      applyFilters({
        entityType: ENTITY_TYPE.ORGANIZATION,
        filters: {
          ...filters,
          [FILTER_SLUG_TYPE[FILTER_TYPES.PAGE]]: 1,
          [FILTER_SLUG_TYPE[FILTER_TYPES.QUERY]]: query,
        },
      });
    },
    [filters, applyFilters],
  );

  return (
    <div className={classes.root}>
      <div className={classes.field_search}>
        <EntityProfileSelector
          allowedEntityTypes={[ENTITY_TYPE.ORGANIZATION]}
          onChange={onSelectOrganization}
          onSearch={onSearchOrganization}
          searchQuery={filters?.[FILTER_SLUG_TYPE[FILTER_TYPES.QUERY]]}
          showRecentlyVisited
          fullWidth
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.ORGANIZATION_SEARCH,
            category: CATEGORIES.SEARCH,
          }}
        />
      </div>
      <OrgListingFilterDropdown
        filterType={FILTER_TYPES.ORGANIZATION_TYPE}
        value={organizationType}
        onChange={onChangeFilter}
        className={classes.field_orgType}
        trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.ORGANIZATION_TYPE }}
      />
      <OrgListingFilterDropdown
        filterType={FILTER_TYPES.COUNTRY}
        value={selectedCountry}
        onChange={onChangeFilter}
        clearFilterTypes={[FILTER_TYPES.CITY]}
        className={classes.field_country}
        trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.COUNTRY }}
      />
      <OrgListingFilterDropdown
        filterType={FILTER_TYPES.CITY}
        value={selectedCity}
        onChange={onChangeFilter}
        className={classes.field_city}
        trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.CITY }}
      />
    </div>
  );
};

export default IndexPageFilters;
