import React, { useMemo, memo } from 'react';

import Typography from 'components/uiLibrary/Typography';

import { createDate } from 'utils/date';
import { TP, DATE_FORMATS } from 'constants/index';
import { useTranslation } from 'src/i18n';

const GroupedDateList = ({
  dates,
  excludeDates,
  startYear,
  showCount = false,
  withBrackets = false,
  filterFutureDates = false,
  getMoreLabel,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { datesLabel, extraDatesCount } = useMemo(() => {
    const today = createDate();

    const updateYearMonthSet = (currentSet, date) => {
      const yearMonthSet = currentSet || new Set();
      let shouldIncrementCount = false;

      if (date) {
        shouldIncrementCount = true;
        yearMonthSet.add(date);
      }

      return {
        value: yearMonthSet,
        shouldIncrementCount,
      };
    };

    const { groupedDates, count } = dates?.reduce(
      (acc, performance) => {
        if (!excludeDates?.includes(performance)) {
          const performanceDate = performance && createDate(performance);

          if (performanceDate) {
            const includeDate = filterFutureDates
              ? performanceDate.isAfter(today, 'day') || performanceDate.isSame(today, 'day')
              : true;

            if (includeDate) {
              const year = performanceDate.format(DATE_FORMATS.YEAR);
              const month = performanceDate.format(DATE_FORMATS.MONTH_ABBR);
              const date = performanceDate.format(DATE_FORMATS.DATE);

              const { value, shouldIncrementCount } = updateYearMonthSet(acc?.groupedDates?.[year]?.[month], date);

              return {
                ...acc,
                groupedDates: {
                  ...(acc?.groupedDates || {}),
                  [year]: {
                    ...(acc?.groupedDates?.[year] || {}),
                    [month]: value,
                  },
                },
                count: shouldIncrementCount ? acc?.count + 1 : acc?.count,
              };
            }
          }
        }

        return acc;
      },
      { groupedDates: {}, count: 0 },
    );

    const concatenatedDates = Object.keys(groupedDates || {})
      .map((year, yearIndex) => {
        const months = groupedDates?.[year];

        return Object.keys(months)
          .map((month, monthIndex) => {
            const monthDates = [...months?.[month]];

            return monthDates
              ?.map((date, dateIndex) => {
                let label = '';
                if (dateIndex === 0) {
                  if (
                    monthIndex === 0 &&
                    ((yearIndex === 0 && (startYear || '')?.toString() !== year) || yearIndex !== 0)
                  ) {
                    label = `${year} `;
                  }

                  label += `${month} `;
                }

                label += date;

                return label;
              })
              ?.join(', ');
          })
          ?.join(', ');
      })
      ?.join(' ');

    return {
      extraDatesCount: count,
      datesLabel: withBrackets ? `(${concatenatedDates})` : concatenatedDates,
    };
  }, [dates, excludeDates, startYear, withBrackets, filterFutureDates]);

  if (extraDatesCount === 0) {
    return null;
  }

  return (
    <>
      {showCount && (
        <Typography color="secondary">
          {getMoreLabel
            ? getMoreLabel(extraDatesCount)
            : t(`${TP}.FN_PLUS_X_MORE_NO_BRACKET`, { number: extraDatesCount })}{' '}
        </Typography>
      )}
      <Typography variant="span" color="secondary" truncate italic>
        ({datesLabel})
      </Typography>
    </>
  );
};

export default memo(GroupedDateList);
